/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'volume-up': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M11.536 14.01A8.47 8.47 0 0014.026 8a8.47 8.47 0 00-2.49-6.01l-.708.707A7.48 7.48 0 0113.025 8c0 2.071-.84 3.946-2.197 5.303z"/><path pid="1" d="M10.121 12.596A6.48 6.48 0 0012.025 8a6.48 6.48 0 00-1.904-4.596l-.707.707A5.48 5.48 0 0111.025 8a5.48 5.48 0 01-1.61 3.89z"/><path pid="2" d="M10.025 8a4.5 4.5 0 01-1.318 3.182L8 10.475A3.5 3.5 0 009.025 8c0-.966-.392-1.841-1.025-2.475l.707-.707A4.5 4.5 0 0110.025 8M7 4a.5.5 0 00-.812-.39L3.825 5.5H1.5A.5.5 0 001 6v4a.5.5 0 00.5.5h2.325l2.363 1.89A.5.5 0 007 12zM4.312 6.39L6 5.04v5.92L4.312 9.61A.5.5 0 004 9.5H2v-3h2a.5.5 0 00.312-.11"/>',
    },
});
